import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Component from "components/component";

import mapDispatchToProps from "../../lib/map-dispatch-to-props";
import * as modalActions from "../../actions/modals";
import { csrfToken } from "../../lib/helpers";
import loginBackground from'./login-background.png';

export class LoginModal extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    onProductPage: PropTypes.bool.isRequired,
    prevModal: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = { email: "", password: "" };
    this.redirectTo = window.location.href;
  }

  handleOnChange = (field, e) => {
    let state = {};


    state[field] = e.target.value;

    this.setState(state);
  };

  openProductInquiry = e => {
    e.preventDefault();
    this.props.actions.modalActions.openModal("product-inquiry");
  };

  login = e => {
    if (this.props.prevModal) {
      localStorage.setItem("prevModal", this.props.prevModal);
    }
    return true;
  }

  render() {
    const { email, password } = this.state;
    const { onProductPage } = this.props;

    if (onProductPage && !this.redirectTo.includes("#configurator")) {
      this.redirectTo += "#configurator";
    }

    return (
      <div className="login-modal-wrapper modal-wrapper">
        <div className="login-form left-modal-inner">
          <h2 className="headline">SIGN IN</h2>
          {onProductPage && (
            <div className="login-modal-content mobile-only">
              <a
                href="#"
                onClick={this.openProductInquiry}
                className="product-inquiry-link"
              >
                Inquire About This Product
              </a>
            </div>
          )}

          <form action="/login" method="post" onSubmit={this.login}>
            <input
              type="hidden"
              name="authenticity_token"
              value={csrfToken()}
            />

            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                name="spree_user[email]"
                className="form-control"
                value={email}
                onChange={this.handleOnChange.bind(this, "email")}
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="spree_user[password]"
                className="form-control"
                value={password}
                onChange={this.handleOnChange.bind(this, "password")}
              />
            </div>

            <input type="hidden" value={this.redirectTo} name="redirect_to" />

            <input
              type="submit"
              name="commit"
              value="Sign In"
              className="large right login-button"
            />

            <div className="request-account-link-wrapper">
              <a
                href={`${gon.signupPath}`}
                className="large right signup-button"
              >
                CREATE AN ACCOUNT
              </a>

              <br className="mobile-only" />
              <br className="mobile-only" />

              <a
                href={gon.forgotPasswordPath}
                className="forgot-password-link"
              >
                FORGOT PASSWORD?
              </a>
            </div>
          </form>

        </div>

        <div className="not-trade-cta desktop-only right-modal-inner">
          <div className="background">
            <img src={loginBackground} alt="Dmitriy Login Welcome" width="100%" />
          </div>
          <div className="trade-member-benefits">
            <h3>Trade Member Benefits</h3>
            <p>Trade Pricing, Customization, Order Online</p>
            <a href="/become-a-trade-member">BECOME A TRADE MEMBER ➔</a>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const product = state.configuratorStore.get("product");
  const onProductPage = !product.isEmpty();
  const { prevModal } = state.modalsStore.get("metaData");

  return { onProductPage, prevModal };
}

export default connect(mapStateToProps, mapDispatchToProps(modalActions))(
  LoginModal
);
